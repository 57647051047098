<template>
   <div class="app-download__wrapper">
        <div class="app-download__container">
            <div class="app-home__logo"><MainLogoIcon class="app-home__logo-img"></MainLogoIcon></div>
            <div>
                <h2>{{$t('DOWNLOAD_NOW')}}</h2>
                <div>{{$t('APP_DESC')}}</div>
            </div>
        </div>
        <div class="app-download__label-wrapper">
            <AppStore :appType="'apple'"></AppStore>
            <AppStore :appType="'android'"></AppStore>
        </div>
    </div>
</template>

<script>
import MainLogoIcon from '@/components/indicator/MainLogoIcon.vue'
import AppStore from '@/components/mobile/app/AppStore.vue'
export default {
    components:{
        MainLogoIcon,
        AppStore
    }
}
</script>

<style>
.app-download__container{
    display: flex;
    color: white;
    align-items: center;
    margin-bottom: 1rem;
}
.app-download__label-wrapper{
}
.app-download__wrapper{
    padding: 0 1rem 1rem 1rem;;
}
</style>