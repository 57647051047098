<template>
  <div class="container has-space">
      <div class="mobile-account__header">
          <div class="mobile-account__close" @click="handleClose()">
              <img src="../../../static/images/icons/icon_close.png">
          </div>
           <router-link :to="{ name: 'myProfile'}">
                <div class="display-flex-sb mb-3rem">   
                    <div class="display-flex-align-center hidden flex-1">
                        <div class="mobile-account__user-icon__wrapper">
                            <img :src="`${s3ImgUrl}/${userInfo.profilePicture}`" class="w-100 h-100">
                        </div>
                        <div class="mobile-account-menu__wrapper">
                            <div class="mobile-account-menu__user-name">{{userInfo.displayName}}</div>
                            <div class="mobile-account-menu__user-join-date">{{$t('MEMBER_SINCE')}} {{userRegistrationDate}}</div>
                        </div>
                    </div>
                    <div ><img src="../../../static/images/icons/icon_pencil.png"></div>
                </div>
          </router-link>
      </div>
        
      <div class="mobile-account__body">
          <div class="mobile-account__body-list__container">
               <div class="mobile-account__body-list__parent-name">{{$t('PERSONAL_SETTING')}}</div>
                <ul class="mobile-account__body-list__child">
                 
                    <router-link :to="{ name: o.pathName}" v-show="o.active" v-for="(o, index) in menuList" :key="index" class="mobile-account__body-list__child-container">
                        <span>{{$t(o.name)}}</span>
                        <div>
                            <span class="mobile-account__body-list__child-info">{{o.value}}</span>
                            <img src="../../../static/images/icons/icon_arrow_right_white.png">
                        </div>
                    </router-link>
                  
                    <li @click="handleLanguage"  class="mobile-account__body-list__child-container"><span>{{$t("LANGUAGE")}}</span>
                        <div>
                            <span class="mobile-account__body-list__child-info">{{currentLanguageObj.displayName}}</span>
                            <img src="../../../static/images/icons/icon_arrow_right_white.png">
                        </div>
                    </li>
                </ul>
          </div>
          <div class="mobile-account__body-list__container">
               <div class="mobile-account__body-list__parent-name">{{$t('LOG_OUT')}}</div>
                <ul class="mobile-account__body-list__child">
                    <li @click="handleLogout()">{{$t('LOG_OUT')}}</li>
                </ul>
          </div>
       
      </div>
     <div class="mobile-account__footer">
        <APPDownloadNow></APPDownloadNow>
        <div  class="footer-mobile__privacy">
            <router-link :to="{name: 'privacypolicy'}">{{$t('PRIVACY_POLICY')}}</router-link>
        </div>
         {{$t('COPYRIGHT')}} © {{currentYear}} asiasport.com
    </div>
  </div>
 <Selection ref="selectionRef" :title="'LANGUAGE'" :data="currentLanguageList" @finishSelectEvent="languageSelectEventChange($event)"></Selection>
</template>

<script>

import Selection from '@/components/modal/Selection.vue'
import config from '@/js/config.js'
import APPDownloadNow from '@/components/mobile/app/APPDownloadNow.vue'

import {mapGetters,mapActions} from 'vuex'
export default {
    components: {
        Selection,
       APPDownloadNow
    },

    data() {
        return {
              s3ImgUrl: config.s3ImgUrl,
              currentYear: '',

              languageList: config.languageList,
              selectedLanguage: {
                  displayName: ''
              },

              isShowPasswordSelect: true,

              menuList: [
                {   
                    id: 1,
                    name: 'PASSWORD',
                    selected: false,
                    pathName: 'changePassword',
                    active: true,
                    value:'',
                },
                {
                    id: 2,
                    name: 'MY_PHONE_NUMBER',
                    selected: false,
                    pathName: 'myPhoneNumber',
                    active: true,
                    value:'',
                },
                {
                    id: 3,
                    name: 'MY_REFERRAL_CODE',
                    selected: false,
                    pathName: 'myReferralCode',
                    active: true,
                    value: '',
                }
            ]
        }
    },
    computed: {
        ...mapGetters([
            'isMobile',
            'isLogin',
            "userInfo",
            "userRegistrationDate",
            "currentLanguageObj",
            "currentLanguageList",
            "currentLocale"
        ]),
    },
    mounted() {
        this.init();
    },
    methods: {
          ...mapActions([
            "currentLocaleChangeEvent",
            "postUpdateProfilePreferredLanguage",
            "getRetrieveUserInfo",
            "setUserData",
            "postLogout",
            "logoutEvent",
            "toastrChangeEvent",
            "commonVerifyLogin"
        ]),
        
        init() {
            this.commonVerifyLogin();
            //if in desktop version, redirect to my profile page
            if (!this.isMobile) {
                this.$router.push({ name: 'myProfile'}); 
            }
            if (typeof this.userInfo !== 'undefined') {
               this.menuList[2].value = this.userInfo.referralCode; 
            }
            
            //get current year
            this.currentYear = new Date().getFullYear();

            this.getRetrieveUserInfoData();
            this.hideChangePassword();

        },
        //handle Close 
        handleClose() {
             this.$router.push(`/${this.currentLocale}`); 
        },

        //handle language
        handleLanguage() {
            this.$refs.selectionRef.handleOpen();
        },

        //handle logout
        async handleLogout(){
            let params = {
                token: this.userInfo.token
            }

            const result = await this.postLogout(params);
            this.logoutEvent();
            if (this.$route.meta.parent === 'account') {
                this.$router.push(`/${this.currentLocale}`); 
            }
            // if (result.result.businessCode === 0) {
              
            // } else {
            //     this.$root.toastrMsg = result.result.message;
            //     this.$root.toastrType = 'info';
            //     this.$root.toastrPosition = "top";
            //     this.$root.$refs.toastrRef.handleOpen(); 
            // }
        },

        // get user info
        async getRetrieveUserInfoData() {
            let params = {
                token: this.userInfo.token,
                timeZone:this.$tools.getCurrentTimeZone()

            }
            
            const result = await this.getRetrieveUserInfo(params);

            this.setUserData(result.result.data);
             this.menuList[2].value = this.userInfo.referralCode;
        },

        //after user select language
        async languageSelectEventChange(e) {

             this.currentLocaleChangeEvent(e.locale);

             let params = {
                 preferredLanguage: e.locale,
                 token: this.userInfo.token
             };

             const result = await this.postUpdateProfilePreferredLanguage(params);
             
             if (result.result.businessCode === 0) {
                this.getRetrieveUserInfoData();
             } else {
                let params={
                    isOpen:true,
                    msg:result.result.message,
                    type:'info'       
                }
                this.toastrChangeEvent(params);

                // this.$root.toastrMsg = result.result.message;
                // this.$root.$refs.toastrRef.handleOpen(); 
             }
              
            //this.currentLanguageObj = e;
        },
        hideChangePassword() {
             if (this.userInfo.authProvider !== "LOCAL") {
                    this.menuList.forEach(x => {
                        if (x.id === 1) { // change password
                            x.active = false
                        }
                    });               
             }
         }
    },
}
</script>

<style>
.mobile-account-menu__wrapper{
    width: 70%;
}
.mobile-account-menu__user-join-date{
    color: #6F8C9B;
    font-size: 0.6875rem;
}
.mobile-account__user-icon__wrapper{
    width: 2.25rem;
    height: 2.25rem;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    border: .3rem solid var(--bg-selected);
    margin-right: 1.5rem;
    overflow: hidden;
}
.mobile-account__close{
    padding:0 0 1.5rem 0.5rem;
}
.mobile-account__body-list__container{
    margin-bottom: 2.1875rem;
}
.mobile-account__body-list__parent-name{
    font-size: 0.6875rem;
    color: var(--color-grey-80);
    margin-bottom: 1rem;
}
.mobile-account__body-list__child-container{
    padding: 0.5rem 0;
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;
}
.mobile-account__body-list__child-info{
     color: var(--color-grey-80);
     margin-right: 1rem;
}
.mobile-account__footer{
    font-size: 0.6875rem;
    color: #5C7784;
    position: absolute;
    bottom: 1rem;
}
</style>